.circle-green{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--green);
}
.circle-green-wrapper{
    width: 20px;
    height: 20px;
    background-color: var(--lightGreenBg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle-green-wrapper-1{
    width: 46px;
    height: 20px;
    background-color: var(--lightGreenBg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
}
.paid-wrapper{
    font-weight: 600;
    color: var(--green);
}
.txn-wrapper{
    padding-bottom: 8px;
    border-bottom: 1px solid var(--tableHeaderGray);
}