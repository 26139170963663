.plan-card {
    border-radius: 6px;
    background-color: var(--white);
    padding: 20px;
}
.plan-type{
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    color: var(--gray);
}

.plan-type-circle{
    width: 10px;
    height: 10px;
    border: 1px solid var(--primary);
    border-radius: 50px;
    background-color: var(--primary);
}
.plan-type-circle1{
    width: 10px;
    height: 10px;
    border: 1px solid var(--lightsGray);
    border-radius: 50px;
}

.edit1-wrapper{
    width: 13px;
    height: 13px;
}