.no-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 400px;
    width: 100%;
}

.no-data-img {
    width: 40%;
    max-width: 200px;
}