
.illustration-area-wrapper {
  padding: 30px;
  height: 100vh;
  background-color: var(--lightbg);
}

.illustration-area {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-content-wrapper {
    padding: 20px 60px;
    text-align: left;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}


@media only screen and (max-width: 998px) {
  .illustration-area-wrapper {
    padding: 30px;
    height: unset;
  }

  .illustration-area img {
    width: 100%;
  }

  .main-content-wrapper {
    padding: 20px 30px;
}

}