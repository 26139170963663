.status-edit-wrapper{
    display: inline-block;
    padding: 4px 15px;
    background: var(--lightGreenBg);
    color: var(--green);
    border-radius: 30px;
    cursor: pointer;
}
.status-edit-wrapper p{
    font-size: 0.87rem;
    font-weight: 600;
    padding-right: 5px;
}

.active-status {
    background: var(--lightGreenBg);
    color: var(--green);
}

.left-status {
    background: var(--lightRedBg);
    color: var(--red);
}

.freeze-status {
    background: var(--lightYellow);
    color: var(--yellow);
}

.won-status {
    background: var(--lightYellow);
    color: var(--yellow);
}

.closed-status {
    background: var(--lightRedBg);
    color: var(--red);
}

.table-header-area {
    background-color: var(--tableHeaderGray);
    width: 100%;
}
.table-header-area th {
    padding: 8px 6px;
}

.table-row {
    width: 100%;
    border-bottom: 1px solid var(--whitesmoke);
}

.table-row td {
    padding: 16px 6px;
}