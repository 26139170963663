body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #7367F0;
  --lightPrimary: #a29bf8;
  --lightbg: #F8F8F8;
  --gray: #5E5873;
  --lightGray: #6E6B7B;
  --lightestGray: #B9B9C3;
  --lightsGray: #82868B;
  --white: #ffffff;
  --gainsboro: gainsboro;
  --whitesmoke: whitesmoke;
  --lightPrimaryBg: #EEEDFD;
  --skyBlue: #00CFE8;
  --lightSkyBlueBg: #E0F9FC;
  --red: #EA5455;
  --lightRedBg: #FCEAEB;
  --green: #28C76F;
  --lightGreenBg: #E5F8EE;
  --tableHeaderGray: #F3F2F7;
  --tertiary: #00CFE8;
  --lightYellow: #FFF3E8;
  --yellow: #FF9F43;
}
