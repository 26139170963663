.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
}

.pagination .pageNo {
  color: black;
  padding: 0px 16px;
  margin: 6px 4px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

}


.page-body {
  background-color: var(--white);
  border-radius: 30px;
  display: flex;
}

.pagination span {
  display: flex;
  border-radius:50%;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  margin: 0px 15px;
  background-color: var(--white);
}


.disabled {
  background-color: var(--lightestGray) !important;
  color: var(--gray) !important;
}

.pagination .pageNo.active {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 30px;
}

.pagination .pageNo:hover:not(.active) {
  background-color: var(--primary);
  border-radius: 30px;
  color: var(--white);
}
.page-visible {
  display: inline-block;
}
.page-invisible {
  display: none;
}
