.avatar6-wrapper{
    width: 104px;
    height: 104px;
    border-radius: 6px;
    position: relative;
}
.avatar6-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.camera{
    position: absolute;
    bottom: -20px;
    right: -20px;
}

.upload-file-input {
    visibility: hidden;
}

.lh-0{
    line-height: 0px;
}