.table-wrapper {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 0px 10px gainsboro;
    background-color: var(--white);
}

.table-title-wrapper {
    padding: 30px;
}

.table-header {
    background-color: var(--tableHeaderGray);
    padding: 6px 30px;
    display: flex;
    width: 100%;
}

.table-data-row {
    padding: 12px 30px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--whitesmoke);
    align-items: center;
}