.iconClass {
    position: absolute;
    right: 20px;
    cursor: pointer;
    overflow: hidden;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 20px;
    top: 8px;
}

.rc-time-picker-input{
    border: none;
    outline: none;
    padding: 0px;
    height: 100%;
    font-size: 14px;
    color: var(--gray) !important;
}
