.row {
  margin-left: 0px;
  margin-right: 0px;
}


.btn-primary {
  background-color: var(--primary);
}

.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  opacity: 0.6;
  color: var(--primary);
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
  font-size: 14px;
}

.btn-outline-primary:hover {
  color: var(--lightPrimary);
  border-color: var(--lightPrimary);
  background-color: white;
}

.btn-tertiary {
  background-color: var(--tertiary);
}

.btn-tertiary:hover {
  opacity: 0.6;
}

.text-primary {
  color: var(--primary) !important;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
