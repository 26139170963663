.avatar0{
    width: 105px;
    height: 104px;
    border-radius: 10px;
}
.right0{
    width: 38px;
    height: 38px;
    padding: 8px;
    background: rgba(115, 103, 240, 0.12);
    border-radius: 5px;
}
.small-icon{
    width: 38px;
    height: 38px;
    padding: 8px;
    border-radius: 5px;
    background: rgba(40, 199, 111, 0.12);
}
.star-img{
    width: 18px;
    height: 18px;
}