* {
  margin: 0px;
  box-sizing: border-box;
}

@import './globalStyle/bootstrap-overwrite.css';
@import './globalStyle/text.css';

.card {
  padding: 30px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 0px 10px var(--whitesmoke);
  border: none;
}
.card-1 {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 0px 10px var(--whitesmoke);
  border: none;
}

.small-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.small-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

a {
  text-decoration: none;
}

.avatar6-wrapper {
  width: 104px;
  height: 104px;
  border-radius: 6px;
  position: relative;
}
.avatar6-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.camera {
  position: absolute;
  bottom: -20px;
  right: -20px;
}

.upload-file-input {
  visibility: hidden;
}

/* overriding bootstrap style */
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--lightPrimary);
  border-color: var(--lightPrimary);
}



.table-wrapper {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 10px gainsboro;
  background-color: var(--white);
}

.table-title-wrapper {
  padding: 30px;
}

.table-header {
  background-color: var(--tableHeaderGray);
  padding: 6px 30px;
  display: flex;
  width: 100%;
}

.table-data-row {
  padding: 12px 30px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--whitesmoke);
}


.spinner-edit-profile {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 3px solid  rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: absolute;
  top: 42%;
  left: 42%;
  z-index: 100;
}
.disabled{
  pointer-events: none;
  cursor: none;
}

.cursor-pointer{
  cursor: pointer;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.logout-icon-btn {
 position: absolute;
 top: 30px;
 right: 30px;
}
