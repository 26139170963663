span {
    text-decoration: none;
    cursor: pointer;
}

button:disabled,
button[disabled]{
  background-color: var(--lightGray);
  color: var(--white);
}

button:disabled, button[disabled]:hover{
  cursor: no-drop;
  cursor: not-allowed;
}
