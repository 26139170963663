.header-2,
.header-3,
.header-4,
.header-5,
.header-6 {
  font-weight: 500;
  color: var(--gray);
}

.header-2 {
  font-size: 2rem;
}

.header-3 {
  font-size: 1.12rem;
}

.header-4 {
  font-size: 1.5rem;
}

.header-5 {
  font-size: 1.25rem;
}

.header-6 {
  font-size: 1rem;
}

.subHeading {
  font-size: 0.87rem;
  color: var(--lightGray);
  word-break: break-all;
}

.label {
  font-size: 0.75rem;
  color: var(--lightGray);
}

.header-7 {
  font-size: 1.12rem;
  font-weight: 500;
  line-height: 22px;
  color: var(--gray);
}

.header-8 {
  font-size: 0.87rem;
  font-weight: 500;
  color: var(--gray);
}

.big-amount {
  font-size: 50px;
  font-weight: 700;
}

.package-type {
  font-size: 0.87rem;
  font-weight: 400;
  color: var(--lightGray);
}

.label-resize {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 15px;
  color: var(--lightGray);
}

.light-gray-text {
  color: var(--lightGray);
}



@media only screen and (max-width: 998px) {
 
  html {
    font-size: 14px;
  }

  .header-2 {
    font-size: 1.5rem;
  }

}