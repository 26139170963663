.rounded-icon {
  padding: 16px;
  border-radius: 50%;
  overflow: hidden;
}

.rounded-icon.primary {
  background-color: var(--lightPrimaryBg);
}

.rounded-icon.primary svg {
  color: var(--primary);
}

.rounded-icon.skyBlue {
    background-color: var(--lightSkyBlueBg);
  }
  
  .rounded-icon.skyBlue svg {
    color: var(--skyBlue);
  }

  .rounded-icon.red {
    background-color: var(--lightRedBg);
  }
  
  .rounded-icon.red svg {
    color: var(--red);
  }

  .rounded-icon.green {
    background-color: var(--lightGreenBg);
  }
  
  .rounded-icon.green svg {
    color: var(--green);
  }
