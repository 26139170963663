.side-bar {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    box-shadow: 0px 0px 10px var(--gainsboro);
}

.brand-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
    border-bottom: 1px solid var(--whitesmoke);
}

.navigation-area {
    padding: 30px 10px;
}

.gym-logo-wrapper {
   width: 100px;
   height: 100px;
   border-radius: 10px;
   overflow: hidden;
}

.gym-logo-wrapper img {
   object-fit: contain;
   width: 100%;
   height: 100%;
}


.navigation-item {
    padding: 10px 10px;
    border-radius: 8px;
    display: flex;
    justify-content: start;
    margin-bottom: 4px;
    align-items: center;
 }

 .navigation-item svg {
    color: var(--lightGray);
    font-size: 22px;
    margin-right: 4px;
}

.navigation-item:hover {
   background-color: var(--lightPrimary);
}

.navigation-item:hover svg{
    color: var(--white);
 }

 .navigation-item:hover p{
    color: var(--white);
 }

 .navigation-item.active svg{
    color: var(--white);
 }

 .navigation-item.active p{
    color: var(--white);
 }

 .navigation-item.active {
    background-color: var(--primary);
 }

 @media only screen and (max-width: 990px) {
    .navigation-item {
        justify-content: center;
     }

     .brand-area img {
        width: 100%;
    }

    .gym-logo-wrapper {
      width: 50px;
      height: 50px;
   }
  
  }

