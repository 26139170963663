.trainer-img-wrapper{
    width: 98px;
    height: 99px;
    border-radius: 6px;
    overflow: hidden;
}
.trainer-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.mail-img{
    width: 18px;
    height: 18px;
}

.trainer-card {
    padding: 20px;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0px 0px 10px var(--whitesmoke);
    border: none;
}
