.spinner-2 {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 3px solid  var(--lightPrimary);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
