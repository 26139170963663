.search-input {
    padding: 12px 50px 12px 20px;
    border-radius: 4px;
    width: 100%;
    border: none;
}

.search-input:focus {
    outline: none;
    box-shadow: 0px -10px 20px gainsboro;
}

.search-input::placeholder {
    color: var(--lightestGray);
}


.search-input-wrapper {
    position: relative;
}

.search-wrapper {
    position: relative;
}

.suggestion-list {
    width: 100%;
    background: white;
    border-top: 1px solid gainsboro;
    padding: 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 10px 20px gainsboro;
    position: absolute;
    z-index: 100;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0px, -50%);
    color: var(--lightestGray);
}