input::placeholder {
  color: var(--lightestGray) !important;
  font-size: 12px;
}

.form-control {
  font-size: 14px;
  color: var(--gray) !important;
}
.form-error{
  border-color: red;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-error:focus {
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
  border-color: red;
}
