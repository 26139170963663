.modal-content {
  border-radius: 8px;
}

.modal__icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.modal {
  background: #0000007d;
}
