.multi-dropdown__placeholder {
    color: var(--lightestGray) !important;
    font-size: 12px;
}
.multi-dropdown-error__placeholder{
    color: var(--lightestGray) !important;
    font-size: 12px;
}

.multi-dropdown__control {
    font-size: 14px;
    color: var(--gray) !important;
}

.multi-dropdown-error__control {
    border-color: red !important;
}

.multi-dropdown__control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.multi-dropdown-error__control:focus {
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
    border-color: red !important;
}