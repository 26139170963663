.card {
    border-radius: 8px;
    overflow: 8px;
    background-color: var(--white);
}

.cardHeader {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableHeader {
    background-color: var(--tableHeaderGray);
    width: 100%;
}
.tableHeader th {
    padding: 8px 6px;
}

.tableDataRow {
    width: 100%;
    border-bottom: 1px solid var(--whitesmoke);
}

.tableDataRow td {
    padding: 16px 6px;
}