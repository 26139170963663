.dashboard-body {
    display: flex;
    height: 100vh;
}

.side-bar-wrapper {
    width: 15%;
    height: 100%;
}

.main-area-wrapper {
    width: 85%;
    height: 100%;
    overflow-y: scroll;
    padding: 20px 30px 100px 20px;
    background-color: var(--whitesmoke);
}

.sms-counter {
    background-color: var(--lightRedBg);
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 50px;
    padding: 20px;
    box-shadow: 0px 0px 10px gainsboro ;
    cursor: pointer;
}

@media only screen and (max-width: 778px) {
    .main-area-wrapper {
        padding: 20px 10px 100px 20px;
    }
  }